import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  id: "global-footer",
  "class": "d-none d-sm-block",
  role: "contentinfo"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  id: "footer-links"
};
var _hoisted_4 = ["href"];
var _hoisted_5 = ["href"];
var _hoisted_6 = ["href"];
var _hoisted_7 = ["href"];
var _hoisted_8 = ["href"];
var _hoisted_9 = ["href"];
var _hoisted_10 = ["href"];
var _hoisted_11 = {
  id: "copyright"
};
var _hoisted_12 = ["href"];
var _hoisted_13 = ["href"];
var _hoisted_14 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_b_col = _resolveComponent("b-col");
  var _component_b_row = _resolveComponent("b-row");
  var _directive_t = _resolveDirective("t");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_row, null, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        "class": "pl-4"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("img", {
            src: $options.getLogoUrl(),
            alt: "AirProducts Home",
            width: "150",
            height: "30"
          }, null, 8, _hoisted_2)];
        }),
        _: 1
      }), _createVNode(_component_b_col, null, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_3, [_createElementVNode("a", {
            "class": "link link--social",
            target: "_blank",
            rel: "noopener",
            href: $options.getTelephone(),
            title: "Call us at 18005550213",
            "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'phone'}}]"
          }, _cache[0] || (_cache[0] = [_createElementVNode("svg", {
            "class": "footer-icon"
          }, [_createElementVNode("title", {
            "class": "title--phone",
            lang: "en"
          }, "phone"), _createElementVNode("use", {
            "xlink:href": "#icon-phone"
          }), _createElementVNode("svg", {
            viewBox: "0 0 55.96 56.01",
            id: "icon-phone"
          }, [_createElementVNode("path", {
            d: "M55.9 45.78c-.44-2.92-3.17-6.11-7.87-9.21-6.76-4.45-8.65-3.27-11.19-1l-.64.58-.08.07A3.85 3.85 0 0 1 34 37.43a1.12 1.12 0 0 1-.84-.34c-2-1.87-7.07-6.77-7.27-7S21.12 25 19.16 23a.93.93 0 0 1-.34-.75A3.09 3.09 0 0 1 20 20.58l.09-.07.77-.66c2.68-2.24 4-4.11-.6-11.2-3.71-5.8-6.03-8.13-8.5-8.57C10.22-.2 8.7.27 6.88 1.6 3.71 3.79.57 6.27.07 10.43-.67 16.51 4.4 25.2 16.63 38.76l.1.1C28.39 50.53 37.05 56 43.9 56h.68c4.89-.26 8-3.43 10.19-6.15a5 5 0 0 0 1.13-4.07z"
          })])], -1)]), 8, _hoisted_4), _createElementVNode("a", {
            "class": "link link--social",
            target: "_blank",
            rel: "noopener",
            href: $options.getEmail(),
            title: "Email us",
            "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'mail'}}]"
          }, _cache[1] || (_cache[1] = [_createElementVNode("svg", {
            "class": "footer-icon",
            "aria-labelledby": "title--mail",
            role: "img"
          }, [_createElementVNode("title", {
            "class": "title--mail",
            lang: "en"
          }, "mail"), _createElementVNode("use", {
            "xlink:href": "#icon-mail"
          }), _createElementVNode("svg", {
            viewBox: "0 0 56 36",
            id: "icon-mail"
          }, [_createElementVNode("path", {
            d: "M29.42 24.41L53.82 0H2.14l24.41 24.41a2 2 0 0 0 2.87 0zM.31.94A2 2 0 0 0 0 2v32a2 2 0 0 0 .31 1.06L17.37 18zM55.68.91L38.6 18l17.08 17.08A2 2 0 0 0 56 34V2a2 2 0 0 0-.32-1.09z"
          }), _createElementVNode("path", {
            d: "M30.77 25.82a3.94 3.94 0 0 1-5.57 0l-6.45-6.45L2.14 36h51.69L37.22 19.37z"
          })])], -1)]), 8, _hoisted_5), _createElementVNode("a", {
            "class": "link link--social",
            target: "_blank",
            rel: "noopener",
            href: $options.getFBLink(),
            title: "Like us on Facebok text",
            "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'facebook'}}]"
          }, _cache[2] || (_cache[2] = [_createElementVNode("svg", {
            "class": "footer-icon",
            "aria-labelledby": "title--facebook",
            role: "img"
          }, [_createElementVNode("title", {
            "class": "title--facebook",
            lang: "en"
          }, "facebook"), _createElementVNode("use", {
            "xlink:href": "#icon-facebook"
          }), _createElementVNode("svg", {
            viewBox: "0 0 1024 1024",
            id: "icon-facebook"
          }, [_createElementVNode("path", {
            d: "M785.243.183L643.596 0C484.22 0 381.321 98.939 381.321 251.979v116.242H238.76c-12.307 0-22.298 9.321-22.298 20.897v168.331c0 11.576 9.992 20.897 22.298 20.897h142.561v424.757c0 11.576 9.992 20.897 22.298 20.897h185.938c12.368 0 22.298-9.382 22.298-20.897V578.346h166.687c12.307 0 22.298-9.382 22.298-20.897V389.118a20.23 20.23 0 0 0-6.446-14.793l-.012-.011a22.99 22.99 0 0 0-15.629-6.093l-.223.001H611.915v-98.513c0-47.338 12.063-71.402 77.921-71.402h95.405c12.307 0 22.298-9.382 22.298-20.897V21.08c0-11.514-9.992-20.835-22.237-20.897z"
          })])], -1)]), 8, _hoisted_6), _this.country.countrycode !== 'IL' ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            "class": "link link--social",
            target: "_blank",
            rel: "noopener",
            href: $options.getTwitterLink(),
            title: "Follow us on Twitter",
            "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'twitter'}}]"
          }, _cache[3] || (_cache[3] = [_createElementVNode("svg", {
            "class": "footer-icon",
            "aria-labelledby": "title--twitter",
            role: "img"
          }, [_createElementVNode("title", {
            "class": "title--twitter",
            lang: "en"
          }, "twitter"), _createElementVNode("use", {
            "xlink:href": "#icon-twitter"
          }), _createElementVNode("svg", {
            viewBox: "0 0 18 15",
            id: "icon-twitter"
          }, [_createElementVNode("path", {
            "fill-rule": "evenodd",
            d: "M15.874 2.311A3.707 3.707 0 0 0 17.497.27a7.44 7.44 0 0 1-2.346.896 3.692 3.692 0 0 0-6.29 3.366A10.479 10.479 0 0 1 1.254.676a3.665 3.665 0 0 0-.5 1.856 3.69 3.69 0 0 0 1.642 3.072A3.69 3.69 0 0 1 .724 5.14v.046a3.696 3.696 0 0 0 2.96 3.62c-.309.084-.635.13-.972.13-.238 0-.469-.024-.695-.069a3.693 3.693 0 0 0 3.448 2.564A7.405 7.405 0 0 1 .88 13.01c-.298 0-.591-.018-.88-.05a10.432 10.432 0 0 0 5.658 1.661c6.79 0 10.502-5.626 10.502-10.505l-.012-.478a7.372 7.372 0 0 0 1.846-1.907 7.37 7.37 0 0 1-2.12.581z"
          })])], -1)]), 8, _hoisted_7)) : _createCommentVNode("", true), _createElementVNode("a", {
            "class": "link link--social",
            target: "_blank",
            rel: "noopener",
            href: $options.getLinkedinLink(),
            title: "Connect with us on LinkedIn",
            "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'linkedin'}}]"
          }, _cache[4] || (_cache[4] = [_createElementVNode("svg", {
            "class": "footer-icon",
            "aria-labelledby": "title--linkedin",
            role: "img"
          }, [_createElementVNode("title", {
            "class": "title--linkedin",
            lang: "en"
          }, "linkedin"), _createElementVNode("use", {
            "xlink:href": "#icon-linkedin"
          }), _createElementVNode("svg", {
            viewBox: "0 0 17 17",
            id: "icon-linkedin"
          }, [_createElementVNode("path", {
            "fill-rule": "evenodd",
            d: "M16.932 10.399v6.545h-3.63v-6.11c0-1.536-.524-2.58-1.838-2.58-1.004 0-1.597.707-1.862 1.387-.095.245-.124.588-.124.927v6.375H5.851S5.9 6.6 5.851 5.528H9.48v1.618c-.005.013-.016.024-.02.039h.02v-.04c.485-.777 1.343-1.885 3.271-1.885 2.393 0 4.182 1.631 4.182 5.139zM2.054.023C.813.023 0 .875 0 1.998 0 3.09.789 3.97 2.007 3.97h.022c1.268 0 2.054-.88 2.054-1.973C4.06.875 3.297.023 2.053.023zM.217 16.944h3.628V5.527H.217z"
          })])], -1)]), 8, _hoisted_8), _this.country.countrycode !== 'IL' ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            "class": "link link--social",
            target: "_blank",
            rel: "noopener",
            href: $options.getYoutubeLink(),
            title: "Subscribe to our YouTube Channel",
            "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'youtube'}}]"
          }, _cache[5] || (_cache[5] = [_createElementVNode("svg", {
            "class": "footer-icon",
            "aria-labelledby": "title--youtube",
            role: "img"
          }, [_createElementVNode("title", {
            "class": "title--youtube",
            lang: "en"
          }, "youtube"), _createElementVNode("use", {
            "xlink:href": "#icon-youtube"
          }), _createElementVNode("svg", {
            viewBox: "0 0 18 13",
            id: "icon-youtube"
          }, [_createElementVNode("path", {
            "fill-rule": "evenodd",
            d: "M17.596 2.21c.179.997.404 2.47.404 4.29-.045 1.82-.225 3.293-.404 4.29-.18.91-.943 1.603-1.886 1.733-1.48.217-3.949.477-6.732.477-2.738 0-5.208-.26-6.734-.477C1.302 12.393.54 11.7.36 10.79A24.45 24.45 0 0 1 0 6.5c0-1.82.18-3.293.36-4.29C.538 1.3 1.301.607 2.243.477A48.279 48.279 0 0 1 8.978 0c2.783 0 5.207.26 6.732.477.943.13 1.707.823 1.886 1.733zM7.182 9.533L12.57 6.5 7.182 3.467v6.066z"
          })])], -1)]), 8, _hoisted_9)) : _createCommentVNode("", true), _this.country.countrycode !== 'IL' ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            "class": "link link--social",
            target: "_blank",
            rel: "noopener",
            href: $options.getInstagramLink(),
            title: "Follow us on Instagram text",
            "data-tracking": "[{'event':'click', 'label': 'socialFtClick', 'data': {'socialNetworkName': 'instagram'}}]"
          }, _cache[6] || (_cache[6] = [_createElementVNode("svg", {
            "class": "footer-icon",
            "aria-labelledby": "title--instagram",
            role: "img"
          }, [_createElementVNode("title", {
            "class": "title--instagram",
            lang: "en"
          }, "instagram"), _createElementVNode("use", {
            "xlink:href": "#icon-instagram"
          }), _createElementVNode("svg", {
            viewBox: "0 0 31.99 32",
            id: "icon-instagram"
          }, [_createElementVNode("path", {
            d: "M16 2.88c4.28 0 4.78 0 6.46.1a8.47 8.47 0 0 1 3 .55 4.79 4.79 0 0 1 1.84 1.19 4.94 4.94 0 0 1 1.2 1.84 8.78 8.78 0 0 1 .55 3c.08 1.68.09 2.19.09 6.46s0 4.78-.09 6.46a8.72 8.72 0 0 1-.55 3 5 5 0 0 1-1.19 1.84 5.1 5.1 0 0 1-1.84 1.2 8.78 8.78 0 0 1-3 .55c-1.69.08-2.19.1-6.46.1s-4.79-.12-6.47-.12a8.84 8.84 0 0 1-3-.55 5.28 5.28 0 0 1-3-3 9 9 0 0 1-.55-3c-.07-1.69-.09-2.19-.09-6.46s0-4.79.09-6.47a9.1 9.1 0 0 1 .55-3 4.9 4.9 0 0 1 1.2-1.83 4.82 4.82 0 0 1 1.84-1.2 8.53 8.53 0 0 1 3-.55c1.64-.09 2.14-.11 6.42-.11zM16 0c-4.35.05-4.86.05-6.6.05a11.67 11.67 0 0 0-3.88.79 7.78 7.78 0 0 0-2.84 1.85A8 8 0 0 0 .83 5.52 12 12 0 0 0 .14 9.4C.06 11.11.05 11.66.05 16s0 4.89.09 6.6a11.91 11.91 0 0 0 .74 3.88 8 8 0 0 0 1.85 2.84 7.75 7.75 0 0 0 2.84 1.84 11.67 11.67 0 0 0 3.88.75c1.7.07 2.25.09 6.59.09s4.89 0 6.6-.09a11.62 11.62 0 0 0 3.88-.75 7.71 7.71 0 0 0 2.83-1.84 7.87 7.87 0 0 0 1.79-2.83 12 12 0 0 0 .74-3.89c.07-1.7.09-2.25.09-6.59s0-4.89-.09-6.6a12 12 0 0 0-.74-3.88 7.54 7.54 0 0 0-1.84-2.84A7.79 7.79 0 0 0 26.48.84 12 12 0 0 0 22.6.1C20.89.05 20.34.05 16 .05z"
          }), _createElementVNode("path", {
            d: "M16 7.78a8.22 8.22 0 1 0 8.22 8.27A8.22 8.22 0 0 0 16 7.78zm0 13.56a5.34 5.34 0 1 1 5.33-5.29A5.34 5.34 0 0 1 16 21.39zM26.46 7.46a1.92 1.92 0 1 1-1.92-1.92 1.92 1.92 0 0 1 1.92 1.92z"
          })])], -1)]), 8, _hoisted_10)) : _createCommentVNode("", true)])];
        }),
        _: 1
      })];
    }),
    _: 1
  }), _createVNode(_component_b_row, null, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        "class": "pl-4 mt-4"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", _hoisted_11, [_withDirectives((_openBlock(), _createElementBlock("span", null, _cache[7] || (_cache[7] = [_createTextVNode("X - Copyright © 1996-2020 Air Products Inc. All Rights Reserved.")]))), [[_directive_t, {
            path: 'ml_3114',
            locale: _this.$i18n.locale
          }]]), _cache[11] || (_cache[11] = _createTextVNode(" | ")), _withDirectives((_openBlock(), _createElementBlock("a", {
            "class": "link link--default",
            href: _this.country.legalnotice,
            target: "_blank"
          }, _cache[8] || (_cache[8] = [_createTextVNode("X - Legal Notice")]), 8, _hoisted_12)), [[_directive_t, {
            path: 'ml_112199',
            locale: _this.$i18n.locale
          }]]), _cache[12] || (_cache[12] = _createTextVNode(" | ")), _withDirectives((_openBlock(), _createElementBlock("a", {
            "class": "link link--default",
            href: _this.country.privacypolicy,
            target: "_blank"
          }, _cache[9] || (_cache[9] = [_createTextVNode("X - Privacy Notice")]), 8, _hoisted_13)), [[_directive_t, {
            path: 'ml_112200',
            locale: _this.$i18n.locale
          }]]), _cache[13] || (_cache[13] = _createTextVNode(" | ")), _withDirectives((_openBlock(), _createElementBlock("a", {
            "class": "link link--default",
            href: _this.country.cookienotice,
            target: "_blank"
          }, _cache[10] || (_cache[10] = [_createTextVNode("X - Cookie Notice")]), 8, _hoisted_14)), [[_directive_t, {
            path: 'ml_144168',
            locale: _this.$i18n.locale
          }]])])];
        }),
        _: 1
      })];
    }),
    _: 1
  })]);
}