import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_b_col = _resolveComponent("b-col");
  var _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createElementBlock("span", null, [_createVNode(_component_b_row, {
    "align-h": "center"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_b_col, {
        cols: "11"
      }, {
        "default": _withCtx(function () {
          return [_withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.country = $event;
            }),
            "class": "dropdown-input",
            onChange: _cache[1] || (_cache[1] = function () {
              return $options.onCountrySelection && $options.onCountrySelection.apply($options, arguments);
            }),
            "aria-label": "select country"
          }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.countries, function (c) {
            return _openBlock(), _createElementBlock("option", {
              key: c.countrycode,
              value: c
            }, _toDisplayString(_ctx.$t(c.translationcode)), 9, _hoisted_1);
          }), 128))], 544), [[_vModelSelect, $data.country]])];
        }),
        _: 1
      })];
    }),
    _: 1
  })]);
}