import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  "class": "row mt-3 d-none d-md-block"
};
var _hoisted_2 = {
  "class": "prompt select-controls-heading",
  style: {
    "color": "#00a8e1"
  },
  role: "heading",
  "aria-level": "2"
};
var _hoisted_3 = {
  "class": "ital"
};
var _hoisted_4 = {
  "class": "prompt",
  style: {
    "color": "#00a8e1"
  },
  role: "heading",
  "aria-level": "2"
};
var _hoisted_5 = {
  "class": "ital"
};
var _hoisted_6 = {
  "class": "prompt",
  style: {
    "color": "#00a8e1"
  },
  role: "heading",
  "aria-level": "2"
};
var _hoisted_7 = {
  "class": "ital"
};
var _hoisted_8 = {
  "class": "row mt-3 d-md-none"
};
var _hoisted_9 = {
  "class": "prompt select-controls-heading",
  style: {
    "color": "#00a8e1"
  },
  role: "heading",
  "aria-level": "2"
};
var _hoisted_10 = {
  "class": "ital"
};
var _hoisted_11 = {
  "class": "prompt",
  style: {
    "color": "#00a8e1"
  },
  role: "heading",
  "aria-level": "2"
};
var _hoisted_12 = {
  "class": "ital"
};
var _hoisted_13 = {
  "class": "prompt",
  style: {
    "color": "#00a8e1"
  },
  role: "heading",
  "aria-level": "2"
};
var _hoisted_14 = {
  "class": "ital"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _this = this;
  var _component_hero = _resolveComponent("hero");
  var _component_Heading = _resolveComponent("Heading");
  var _component_b_col = _resolveComponent("b-col");
  var _component_b_row = _resolveComponent("b-row");
  var _component_product_search = _resolveComponent("product-search");
  var _component_country_select = _resolveComponent("country-select");
  var _component_language_select = _resolveComponent("language-select");
  var _component_download = _resolveComponent("download");
  var _component_contact_form = _resolveComponent("contact-form");
  var _component_b_container = _resolveComponent("b-container");
  var _directive_t = _resolveDirective("t");
  return _openBlock(), _createBlock(_component_b_container, {
    fluid: ""
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_hero), _createVNode(_component_Heading), _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_b_row, {
        "align-h": "center"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_b_col, {
            md: "4",
            lg: "4",
            xl: "4",
            xxl: "4"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_b_row, {
                "align-h": "center"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(_component_b_col, {
                    cols: "11",
                    "class": "text-center"
                  }, {
                    "default": _withCtx(function () {
                      return [_withDirectives((_openBlock(), _createElementBlock("h5", _hoisted_2, _cache[0] || (_cache[0] = [_createTextVNode(" Please type a product name or code ")]))), [[_directive_t, {
                        path: 'ml_112205',
                        locale: _this.$i18n.locale
                      }]]), _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_3, _cache[1] || (_cache[1] = [_createTextVNode(" Type at least 3 letters of the name or a complete code ")]))), [[_directive_t, {
                        path: 'ml_112206',
                        locale: _this.$i18n.locale
                      }]])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              })];
            }),
            _: 1
          }), _createVNode(_component_b_col, {
            md: "4",
            lg: "4",
            xl: "4",
            xxl: "4"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_b_row, {
                "align-h": "center"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(_component_b_col, {
                    cols: "11",
                    "class": "text-center"
                  }, {
                    "default": _withCtx(function () {
                      return [_withDirectives((_openBlock(), _createElementBlock("h5", _hoisted_4, _cache[2] || (_cache[2] = [_createTextVNode("Select your country")]))), [[_directive_t, {
                        path: 'ml_112207',
                        locale: _this.$i18n.locale
                      }]]), _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_5, _cache[3] || (_cache[3] = [_createTextVNode(" If your country is not listed, please select 'Other' ")]))), [[_directive_t, {
                        path: 'ml_112208',
                        locale: _this.$i18n.locale
                      }]])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              })];
            }),
            _: 1
          }), _createVNode(_component_b_col, {
            md: "4",
            lg: "4",
            xl: "4",
            xxl: "4"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_b_row, {
                "align-h": "center"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(_component_b_col, {
                    cols: "11",
                    "class": "text-center"
                  }, {
                    "default": _withCtx(function () {
                      return [_withDirectives((_openBlock(), _createElementBlock("h5", _hoisted_6, _cache[4] || (_cache[4] = [_createTextVNode("112209-Select your language")]))), [[_directive_t, {
                        path: 'ml_112209',
                        locale: _this.$i18n.locale
                      }]]), _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_7, _cache[5] || (_cache[5] = [_createTextVNode(" 112210-The selected SDS is available in these languages: ")]))), [[_directive_t, {
                        path: 'ml_112210',
                        locale: _this.$i18n.locale
                      }]])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      }), _createVNode(_component_b_row, {
        "align-h": "center"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_b_col, {
            md: "4",
            lg: "4",
            xl: "4",
            xxl: "4"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_product_search, {
                onProductSelected: $options.selectProduct
              }, null, 8, ["onProductSelected"])];
            }),
            _: 1
          }), _createVNode(_component_b_col, {
            md: "4",
            lg: "4",
            xl: "4",
            xxl: "4"
          }, {
            "default": _withCtx(function () {
              return [_this.displayCountryLanguage ? (_openBlock(), _createBlock(_component_country_select, {
                key: 0,
                onCountrySelected: $options.updateProspectMaterials
              }, null, 8, ["onCountrySelected"])) : _createCommentVNode("", true)];
            }),
            _: 1
          }), _createVNode(_component_b_col, {
            md: "4",
            lg: "4",
            xl: "4",
            xxl: "4"
          }, {
            "default": _withCtx(function () {
              return [_this.displayCountryLanguage ? (_openBlock(), _createBlock(_component_language_select, {
                key: 0,
                onLanguageSelected: $options.updateProspectMaterials
              }, null, 8, ["onLanguageSelected"])) : _createCommentVNode("", true)];
            }),
            _: 1
          })];
        }),
        _: 1
      })]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_b_row, {
        "align-h": "center"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_b_col, {
            xs: "12",
            sm: "12"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_b_row, {
                "align-h": "center"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(_component_b_col, {
                    cols: "11",
                    "class": "text-center"
                  }, {
                    "default": _withCtx(function () {
                      return [_withDirectives((_openBlock(), _createElementBlock("h5", _hoisted_9, _cache[6] || (_cache[6] = [_createTextVNode(" Please type a product name or code ")]))), [[_directive_t, {
                        path: 'ml_112205',
                        locale: _this.$i18n.locale
                      }]]), _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_10, _cache[7] || (_cache[7] = [_createTextVNode(" Type at least 3 letters of the name or a complete code ")]))), [[_directive_t, {
                        path: 'ml_112206',
                        locale: _this.$i18n.locale
                      }]])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              }), _createVNode(_component_product_search, {
                onProductSelected: $options.selectProduct
              }, null, 8, ["onProductSelected"])];
            }),
            _: 1
          }), _createVNode(_component_b_col, {
            xs: "12",
            sm: "12"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_b_row, {
                "align-h": "center"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(_component_b_col, {
                    cols: "11",
                    "class": "text-center"
                  }, {
                    "default": _withCtx(function () {
                      return [_withDirectives((_openBlock(), _createElementBlock("h5", _hoisted_11, _cache[8] || (_cache[8] = [_createTextVNode("Select your country")]))), [[_directive_t, {
                        path: 'ml_112207',
                        locale: _this.$i18n.locale
                      }]]), _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_12, _cache[9] || (_cache[9] = [_createTextVNode(" If your country is not listed, please select 'Other' ")]))), [[_directive_t, {
                        path: 'ml_112208',
                        locale: _this.$i18n.locale
                      }]])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              }), _this.displayCountryLanguage ? (_openBlock(), _createBlock(_component_country_select, {
                key: 0,
                onCountrySelected: $options.updateProspectMaterials
              }, null, 8, ["onCountrySelected"])) : _createCommentVNode("", true)];
            }),
            _: 1
          }), _createVNode(_component_b_col, {
            xs: "12",
            sm: "12"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_b_row, {
                "align-h": "center"
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(_component_b_col, {
                    cols: "11",
                    "class": "text-center"
                  }, {
                    "default": _withCtx(function () {
                      return [_withDirectives((_openBlock(), _createElementBlock("h5", _hoisted_13, _cache[10] || (_cache[10] = [_createTextVNode("112209-Select your language")]))), [[_directive_t, {
                        path: 'ml_112209',
                        locale: _this.$i18n.locale
                      }]]), _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_14, _cache[11] || (_cache[11] = [_createTextVNode(" 112210-The selected SDS is available in these languages: ")]))), [[_directive_t, {
                        path: 'ml_112210',
                        locale: _this.$i18n.locale
                      }]])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              }), _this.displayCountryLanguage ? (_openBlock(), _createBlock(_component_language_select, {
                key: 0,
                onLanguageSelected: $options.updateProspectMaterials
              }, null, 8, ["onLanguageSelected"])) : _createCommentVNode("", true)];
            }),
            _: 1
          })];
        }),
        _: 1
      })]), _createVNode(_component_download, {
        "should-render": _this.$store.state.searchType,
        onDownloadSelected: $options.selectDownload
      }, null, 8, ["should-render", "onDownloadSelected"]), _createVNode(_component_contact_form, {
        "should-render": _this.$store.state.searchType,
        onContactDetails: $options.selectContactDetails
      }, null, 8, ["should-render", "onContactDetails"])];
    }),
    _: 1
  });
}